import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import crypto from "crypto";
import objectHash from "object-hash";

import Schedule from "./views/Schedule";

const HashChecker = ({
  match: {
    params: { workBook, sessionId, hash },
  },
}) => {
  let actualHash;
  // Older years have different hashing method
  if (parseInt(workBook.replace(/[a-zA-Z]/g, "")) < 2023) {
    actualHash = objectHash(`${workBook}-${sessionId}`);
  } else {
    actualHash = crypto
      .createHash("sha1")
      .update(`${workBook}-${sessionId}`)
      .digest("hex");
  }

  return hash === actualHash ? (
    <Schedule sessionId={sessionId} workBook={workBook} />
  ) : (
    <h1>Session not found</h1>
  );
};

HashChecker.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      workBook: PropTypes.string,
      sessionId: PropTypes.string,
      hash: PropTypes.string,
    }),
  }).isRequired,
};

function NotFound() {
  return <h1>Page not found</h1>;
}

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/:hash/:workBook/:sessionId" render={HashChecker} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);
