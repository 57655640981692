import { zipObject, get } from 'lodash';

const forced = true;

const csvJSON = rows => {
  const [headers, ...bodyRows] = rows;

  const forcedHeaders = [
    'Status',
    'PROG',
    'Session ID',
    'Date',
    'Group Split',
    'Group Title',
    'Student Start',
    'Student End',
    'Split Start',
    'Staff Start',
    'Reservation Start',
    'Reservation End',
    'Reserved By',
    'Room Type',
    'num rooms',
    'Group Size',
    'Notes (Not for App)',
    'Reserve #',
    'Reserved Rooms',
    'Room',
    'Event Type',
    'Event Title',
    'Event Subtitle GS Name',
    'Event Speaker Title',
    'Event Speaker Department',
    'Event Speaker Organization',
    'Meal Text',
    'Student Attire',
    'Staff Attire',
    'Breakout_Details',
    'resource_map',
    'resource_guestspeaker',
    'resource_fieldtrip',
    'resource_survey',
    'resource_document',
    'resource_staff',
    'Room Set-Up',
    'Set_Up By',
    'Laptop',
    'Sound',
    'Staff Notes',
    'Announcement',
    'Address',
    'YU2019',
    'YU2019',
  ];

  return bodyRows.map(event => zipObject(forced ? forcedHeaders : headers, event));
};

export const SHEET_URL = 'https://mobile-api.nslcleaders.org/api/v1/sheets/locations/5bb20c47-082c-48fe-8294-814ee36e6eb3/81086144-e30c-4829-8ea4-0ee0f9af14b5';
export const SHEET_NAMES_URL = 'https://mobile-api.nslcleaders.org/api/v1/sheets/names/5bb20c47-082c-48fe-8294-814ee36e6eb3/81086144-e30c-4829-8ea4-0ee0f9af14b5';

export const getSheet = async workBook => {
  const request = await fetch(`${SHEET_URL}/${workBook}`);
  const result = await request.json();

  return csvJSON(get(result, 'data', []));
};

export const getSheetNames = async () => {
  const request = await fetch(SHEET_NAMES_URL);
  const result = await request.json();

  return result;
};

export default {
  getSheet,
  getSheetNames,
  SHEET_URL,
  SHEET_NAMES_URL,
};
