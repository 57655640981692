import { zipObject } from "lodash";

const apiUrl = "https://portal.nslcleaders.org/api";

const getSessions = async (year) => {
  if (year) {
    const request = await fetch(`${apiUrl}/sessions-by-year/?year=${year}`);
    const [...bodyRows] = (await request.json()).data;

    return bodyRows;
  }

  const request = await fetch(
    "https://mobile-api.nslcleaders.org/api/v1/sessions/5bb20c47-082c-48fe-8294-814ee36e6eb3/81086144-e30c-4829-8ea4-0ee0f9af14b5"
  );
  const [headers, ...bodyRows] = (await request.json()).data;

  return bodyRows.map((event) => zipObject(headers, event));
};

export default {
  getSessions,
};
