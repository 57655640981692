/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import { map } from 'lodash';

export const arrayChunks = (array, size) =>
  Array(Math.ceil(array.length / size))
    .fill()
    .map((_, index) => index * size)
    .map(begin => array.slice(begin, begin + size));

export const boldFont = event =>
  event['Event Type'] === 'Activity'
  || event['Event Type'] === 'Breakout'
  || event['Event Type'] === 'Field Trip';

export const parseTime = time => {
  time = moment(time, 'HH:mm:ss');

  // Round to the next minute if not rounded already
  if (time.second()) {
    time.add(1, 'minute').startOf('minute');
  }

  return time.format('h:mma');
};

export const parseDate = date => moment(date).format('dddd, MMMM Do');

export const parseHeaderDate = date => moment(date).format('MMMM D');

// Given a list of items and a criteria function, separate the items into chunks
// when the criteria changes value
// for example: chunkBy([1,1,1,2,3,3,3,3,4,3,3,3], a => a)
// would return: [[1,1,1],[2],[3,3,3,3],[4],[3,3,3]]
// example 2: chunkBy([10,false,0,null,1,'hello'], a => !!a)
// would return: [[10],[false,0,null],[1,'hello']]
export const chunkBy = (list, criteria) => {
  const criteriaList = map(list, criteria);
  let result = [[]];

  criteriaList.forEach((value, index) => {
    if (index !== 0 && criteriaList[index - 1] !== value) {
      result = result.concat([[]]);
    }

    result[result.length - 1] = result[result.length - 1].concat(list[index]);
  });

  return result;
};
