import React, { Component } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import { arrayChunks, boldFont, parseTime } from "../../utils/helpers";

import "./index.css";

const getContainerClassName = (index, length) => {
  if (length > 2) {
    return index === 1 ? "container-three-rows" : "container-padding";
  }
  return index % 2 === 0 ? "container" : "container-two-rows";
};

class SplitEvents extends Component {
  static propTypes = {
    events: PropTypes.shape({
      kind: PropTypes.string,
      split: PropTypes.objectOf(PropTypes.array),
    }).isRequired,
  };

  renderEvent = (event, index) => {
    if (!event["Student Start"]) {
      return null;
    }

    const isBold = boldFont(event);

    return (
      <div key={index} className="split-event">
        <div className="split-event-row-left">
          <h3
            className={isBold ? "split-event-time" : "split-event-time-light"}
          >
            {event["Student End"]
              ? `${parseTime(event["Student Start"])} - ${parseTime(
                  event["Student End"]
                )}`
              : `${parseTime(event["Student Start"])}`}
          </h3>
        </div>
        <div className="split-event-row-left">
          <h3
            className={isBold ? "split-event-title" : "split-event-title-light"}
          >
            {event["Event Title"] || ""}
          </h3>
          {!!event["Event Subtitle GS Name"] && (
            <h3 className="event-description" style={{ fontWeight: "700" }}>
              {event["Event Subtitle GS Name"]}
            </h3>
          )}
          {event["Event Speaker Title"] && (
            <h3 className="event-description">
              {event["Event Speaker Title"]}
            </h3>
          )}
          {event["Event Speaker Department"] && (
            <h3 className="event-description">
              {event["Event Speaker Department"]}
            </h3>
          )}
          {event["Event Speaker Organization"] && (
            <h3 className="event-description">
              {event["Event Speaker Organization"]}
            </h3>
          )}
          {event.Room && <h3 className="event-description">{event.Room}</h3>}
          {event["Student Attire"] && (
            <h3 className="event-description">{event["Student Attire"]}</h3>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { split } = this.props.events;

    const chunkedGroupTitles =
      Object.keys(split).length % 2
        ? arrayChunks(Object.keys(split), 3)
        : arrayChunks(Object.keys(split), 2);

    return map(chunkedGroupTitles, (chunk, chunkIndex) => (
      <div key={chunkIndex} className="split-container">
        {map(chunk, (groupTitle, index) => (
          <div
            key={index}
            className={getContainerClassName(index, chunk.length)}
          >
            <h3
              className={
                index % 2 === 0 ? "group-title" : "group-title-no-padding"
              }
            >
              {groupTitle}
            </h3>
            {map(split[groupTitle], this.renderEvent)}
          </div>
        ))}
      </div>
    ));
  }
}

export default SplitEvents;
