import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, flatMap, mapValues, sortBy, head, map } from 'lodash';

import { boldFont, parseTime, chunkBy } from '../../utils/helpers';
import SplitEvents from '../split-events/SplitEvents';

import './index.css';

const parseEvents = events => {
  const mappedEvents = map(events, e => ({
    ...e,
    'Group Split': e['Group Split'] === 'ALL' ? null : e['Group Split'],
    kind: 'event',
  }));

  const chunkedEvents = chunkBy(
    sortBy(mappedEvents, 'Student Start'),
    event => !!event['Group Split'],
  );

  return flatMap(chunkedEvents, chunk => {
    const firstEvent = head(chunk);

    if (firstEvent['Group Split']) {
      // this is a split events chunk
      const groupedEvents = groupBy(chunk, 'Group Title');

      return [
        {
          kind: 'split',
          'Student Start': firstEvent['Split Start'] || firstEvent['Student Start'],
          split: mapValues(groupedEvents, group => sortBy(group, 'Student Start')),
        },
      ];
    }

    // this is a normal events chunk
    return sortBy(chunk, 'Student Start');
  });
};

const ScheduleItem = ({ events }) => {
  const parsedEvents = parseEvents(events);

  return map(parsedEvents, (event, index) => {
    if (event.kind === 'split') {
      return <SplitEvents key={index} events={event} />;
    }

    const isLight = !boldFont(event);

    return (
      <div key={index} className={index === 16 ? 'event-break' : 'event'}>
        <div className="event-row-left">
          <h3 className={isLight ? 'event-time-light' : 'event-time'}>
            {event['Student End']
              ? `${parseTime(event['Student Start'])} - ${parseTime(event['Student End'])}`
              : `${parseTime(event['Student Start'])}`}
          </h3>
        </div>
        <div className="event-row-right">
          <h3 className={isLight ? 'event-title-light' : 'event-title'}>
            {event['Event Title'] || ''}
          </h3>
          {!!event['Event Subtitle GS Name'] && (
            <h3 className="event-description" style={{ fontWeight: '700' }}>
              {event['Event Subtitle GS Name']}
            </h3>
          )}
          {event['Event Speaker Title'] && (
            <h3 className="event-description">{event['Event Speaker Title']}</h3>
          )}
          {event['Event Speaker Department'] && (
            <h3 className="event-description">{event['Event Speaker Department']}</h3>
          )}
          {event['Event Speaker Organization'] && (
            <h3 className="event-description">{event['Event Speaker Organization']}</h3>
          )}
          {event.Room && <h3 className="event-description">{event.Room}</h3>}
          {event['Student Attire'] && (
            <h3 className="event-description">{event['Student Attire']}</h3>
          )}
          {event['Meal Text'] && <h3 className="event-description">{event['Meal Text']}</h3>}
        </div>
      </div>
    );
  });
};

ScheduleItem.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ScheduleItem;
